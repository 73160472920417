import React from 'react';
import { connect } from 'react-redux';
import { UPDATE_PLATFORM_REGISTRATION } from '../store/actions';
import { graphql } from 'gatsby';
import { Link } from 'gatsby';
import Image from 'components/image/image';
import DataPrepSubpageWrapper from 'components/data-prep/data-prep-subpage-wrapper';
import 'components/data-prep/data-prep.sass';

const numberWithOrdinal = (d) => {
	if (d > 3 && d < 21) {
		return `${d}th`;
	}

	switch (d % 10) {
		case 1:  return `${d}st`;
		case 2:  return `${d}nd`;
		case 3:  return `${d}rd`;
		default: return `${d}th`;
	}
}

const UserWaitList = ({ data, data_prep_registration_reducer, updatePlatformRegistration }) => {
  const d = data.landingPagesYaml.registration;
  const { step, first_name, user_details } = data_prep_registration_reducer;

  return (
    <DataPrepSubpageWrapper step={step} data={d} updatePlatformRegistration={updatePlatformRegistration}>
      <div className="UserComplete k-background-box">
        <Image image_url="layout/bg-data-prep-stripes.svg"
              alt={`background stribes`}
              className="UserComplete__background UserComplete__background--stripes"/>

        <Image image_url="layout/bg-data-prep-stripes2.svg"
              alt={`background stribes`}
              className="UserComplete__background UserComplete__background--stripes2"/>

        <Image image_url="layout/bg-diamond.svg"
              alt={`background square`}
              className="UserComplete__background UserComplete__background--square"/>
        <Image image_url="layout/bg-diamond--blue.svg"
              alt={`background square`}
              className="UserComplete__background UserComplete__background--square--blue"/>

        <Image image_url={d.completed.lead_photo}
              alt={`Andy gray CEO of Kortical smiling`}
              className="UserComplete__leadership"/>

        <div className="UserComplete__content">
          <h4>Hi {first_name},</h4>
          <p>You are on the waiting list!</p>
          <p>We’d love to let you have direct access but we need to control user numbers to maintain the quality of the experience as we ramp up the free Beta. You are in <b>{ user_details ? `the ${numberWithOrdinal(user_details.position_in_queue)}` : null}</b> position on our waiting list and we will let you know as soon as a place becomes available.</p>
          <p>Bear with us while we work to get you access. If you’re interested in the full platform please let us know here and we’ll be in touch shortly.</p>
          <p>Thanks,</p>

          <div className="UserComplete__content__signature d-flex">
            <Image image_url={'content/icon-andy-signature/icon-andy-signature.png'}
                  alt={`Andy Gray CEO of Kortical's signature`}/>
              <p>&amp; Team</p>
          </div>

          <Image image_url={d.completed.team_photo}
                  alt={`Kortical team photo smiling`}
                  className="my-4"/>
          <Link to={'/case-studies/'}
                className="btn k-text--heading-work btn-block btn-xs-inline-block d-flex align-items-center justify-content-center text-normal p-2 w-75 k-margin-0-auto">
            Check out our Case Studies
          </Link>
        </div>
      </div>

    </DataPrepSubpageWrapper>
  )
}

const mapStateToProps = state => {
  return {
    data_prep_registration_reducer: state.registrationReducer.data_prep[state.registrationReducer.data_prep.length - 1]
  }
}

const mapDispatchToProps = dispatch => ({
  updatePlatformRegistration: (field, data) => {
    dispatch(UPDATE_PLATFORM_REGISTRATION(field, data))
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(UserWaitList)

export const pageQuery = graphql`
  query {
    landingPagesYaml {
      registration {
        title
        subtitle
        section
        completed {
          lead_photo
          team_photo
        }
      }
    }
  }
`